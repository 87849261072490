<template>
  <div
    class="pa-1 chip px-3 d-flex justify-space-between align-center"
    close-icon="mdi-close"
  >
    <div class="d-flex align-center">
      <user-photo
        v-model="user.photo"
        class="ml-1 photo"
        size="sm"
        border="thin"
      />
      <span class="text-truncate pl-2 chip-name">{{ user.fullName }}</span>
    </div>

    <v-icon v-if="canClose" @click="onClose">mdi-close</v-icon>
  </div>
</template>
<script>
  import UserPhoto from "@/modules/account/user/views/components/UserPhoto";

  export default {
    props: {
      user: {
        type: Object,
        required: true
      },
      canClose: {
        type: Boolean,
        required: false,
        default: () => false
      }
    },
    components: {
      UserPhoto
    },
    methods: {
      onClose() {
        if (this.canClose) {
          this.$emit("close");
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .photo {
    width: 25px;
    height: 25px;
  }
  .chip {
    background-color: $white-1;
    border-radius: 100px;
    .chip-name {
      max-width: 145px;
    }
  }
</style>
