<template>
  <v-chip
    :color="chipColor[text].color"
    :text-color="chipColor[text].textColor"
  >
    {{ text }}
  </v-chip>
</template>
<script>
  import { UserSituation } from "@/modules/account/user/enum/userSituation.enum";
  export default {
    props: {
      text: {
        required: true,
        type: String
      }
    },
    data: () => ({
      chipColor: {
        [UserSituation.PENDING.description]: {
          color: "situation-1",
          textColor: "white"
        },
        [UserSituation.ACTIVE.description]: {
          color: "situation-2",
          textColor: "white"
        },
        [UserSituation.BLOCKED.description]: {
          color: "situation-3",
          textColor: "white"
        }
      }
    }),
    mounted() {
      if (!this.chipColor[this.text]) {
        throw new Error("Invalid situation!");
      }
    }
  };
</script>
<style lang="scss" scoped></style>
