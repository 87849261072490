<template>
  <v-container>
    <title-header />
    <v-card class="pt-7">
      <v-layout fill-height class="d-flex flex-column justify-space-between">
        <!-- Align top -->
        <div>
          <template>
            <div class="row px-5 d-flex justify-end">
              <v-col cols="12" md="2">
                <v-select
                  :items="profileSelect"
                  item-text="description"
                  item-value="id"
                  outlined
                  @change="setFilter('roles', $event)"
                  color="secondary-color-1"
                  label="Perfil"
                ></v-select>
              </v-col>

              <v-col cols="12" md="3" v-if="userIsAdmin">
                <v-select
                  :items="crcs"
                  item-text="description"
                  item-value="id"
                  outlined
                  @change="setFilter('crcId', $event)"
                  color="secondary-color-1"
                  label="Todos CRCs"
                ></v-select>
              </v-col>

              <v-col cols="9" sm="8" md="3">
                <v-text-field
                  @input="setFilterDebounced('name', $event)"
                  solo
                  flat
                  full-width
                  color="secondary-color-1"
                  background-color="white-1"
                  placeholder="Buscar funcionário"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>

              <v-col cols="3" sm="4" md="2">
                <report-options
                  :fileName="'Funcionários'"
                  :header="headerReport"
                  :body="employees"
                />
              </v-col>
            </div>
            <v-divider></v-divider>
          </template>
          <!-- Selected Employees -->
          <template>
            <p
              v-if="employees.length && !selectedEmployees.length"
              class="d-flex justify-center align-center my-10 mx-5 text-center"
            >
              <v-icon>mdi-account-check-outline</v-icon>
              <span class="ml-1"
                >Você ainda não selecionou nenhum funcionário</span
              >
            </p>
            <div v-if="selectedEmployees.length" class="my-10 mx-4">
              <user-chip-list>
                <user-chip
                  v-for="employee in selectedEmployees"
                  :user="employee"
                  :key="employee.id"
                  :canClose="employee.isSelectable"
                  class="ma-2"
                  @close="removeEmployee(employee)"
                />
              </user-chip-list>
            </div>
          </template>
          <template v-if="!employees.length">
            <div class="mt-5">
              <data-not-found
                v-if="!filterPristine"
                title="Funcionário não encontrado."
                :imgSrc="require('@/assets/user.svg')"
              />
              <data-not-found
                v-if="filterPristine"
                title="Você ainda não cadastrou nenhum funcionário, deseja cadastrar?"
                btnLabel="Cadastrar o primeiro funcionário"
                :btnAction="goToAddUser"
                :imgSrc="require('@/assets/user.svg')"
              />
            </div>
          </template>
          <template v-if="employees.length">
            <v-data-table
              :headers="headers"
              :items="employees"
              :items-per-page="tableInfo.itemsPerPage"
              v-model="selectedEmployees"
              hide-default-footer
              show-select
            >
              <template v-slot:[`item.fullName`]="{ item }">
                <div class="d-flex align-center">
                  <user-photo
                    class="mr-2"
                    v-model="item.photoHash"
                    size="sm"
                    border="thin"
                  />
                  <p class="ma-0">{{ item.fullName }}</p>
                </div>
              </template>
              <template v-slot:[`item.cpf`]="{ item }">
                {{ item.cpf | VMask(masks.cpf) }}
              </template>
              <template v-slot:[`item.situation`]="{ item }">
                <user-situation-chip :text="item.situation" />
              </template>
            </v-data-table>
          </template>
        </div>
        <!-- Align bottom -->

        <v-card-actions v-if="employees.length">
          <pagination
            :currentPage="tableInfo.currentPage"
            :totalPages="tableInfo.totalPages"
            :itemsPerPage="tableInfo.itemsPerPage"
            @currentPageChanged="handleCurrentPageChange($event)"
            @itemsPerPageChanged="handleItemsPerPageChange($event)"
          />
        </v-card-actions>
      </v-layout>
    </v-card>

    <v-row justify="end">
      <v-col cols="12" sm="5" md="4" lg="2">
        <v-btn
          depressed
          class="cancel-button black-3--text"
          block
          @click="backToViewCrc"
          >Cancelar</v-btn
        >
      </v-col>
      <v-col cols="12" sm="5" md="4" lg="2">
        <v-btn
          color="secondary-color-1"
          class="next-step-button white--text"
          block
          @click="handleUpdateStaff"
          >Salvar</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { mapActions, mapGetters } from "vuex";

  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import Pagination from "@/modules/core/views/components/Pagination";
  import ReportOptions from "@/modules/core/views/components/ReportOptions";
  import TableHandler from "@/modules/core/mixins/tableHandler";
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import UserChip from "@/modules/account/user/views/components/UserChip";
  import UserChipList from "@/modules/account/user/views/components/UserChipList";
  import UserPhoto from "@/modules/account/user/views/components/UserPhoto";
  import UserSituationChip from "@/modules/account/user/views/components/UserSituationChip";

  import { commonProfiles, profiles } from "@/modules/core/enums/profiles.enum";

  export default {
    name: "ManageEmployees",

    components: {
      TitleHeader,
      ReportOptions,
      DataNotFound,
      UserSituationChip,
      UserPhoto,
      UserChipList,
      UserChip,
      Pagination
    },
    mixins: [MasksMixin, TableHandler],
    data: () => ({
      headers: [
        {
          text: "Nome",
          align: "start",
          value: "fullName"
        },
        { text: "CPF", value: "cpf" },
        { text: "Perfil", value: "profile" },
        { text: "CRC", value: "crc" },
        { text: "Telefone", value: "phone" },
        { text: "Situação", value: "situation" }
      ],
      headerReport: [
        {
          header: "Nome",
          dataKey: "fullName"
        },
        { header: "CPF", dataKey: "cpf" },
        { header: "Perfil", dataKey: "profile" },
        { header: "CRC", dataKey: "crc" },
        { header: "Telefone", dataKey: "phone" },
        { header: "Situação", dataKey: "situation" }
      ],
      crcs: [],
      employees: [],
      selectedEmployees: [],
      crcId: 0
    }),
    created() {
      this.initFilter("crcId", null);
      this.initFilter("roles", null);
      this.initFilter("name", null);
    },
    async mounted() {
      this.crcId = parseInt(this.$route.params?.id, 10);
      if (this.userIsAdmin) {
        await this.fetchCrcs();
      }
      this.updateData();
    },
    computed: {
      ...mapGetters("user", {
        userIsAdmin: "getUserIsAdmin",
        userIsManagerOperator: "getUserIsManagerOperator"
      }),
      profileSelect() {
        return [
          { description: "Todos", id: "" },
          ...Object.values(commonProfiles)
        ];
      },
      commonProfilesParam() {
        return Object.values(commonProfiles)
          .map(profile => profile.id)
          .join(",");
      }
    },
    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("user", ["getUsers"]),
      ...mapActions("crc", [
        "getCRCs",
        "updateCRC",
        "getUsersFromCRC",
        "updateStaff"
      ]),
      prepareRoles() {
        return this.filters.roles
          ? this.filters.roles
          : this.commonProfilesParam;
      },
      async fetchCrcs() {
        try {
          const { data } = await this.getCRCs();
          this.crcs = [
            {
              description: "Todos",
              id: ""
            },
            ...data
          ];
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },
      async fetchEmployees() {
        try {
          const payload = this.getPayloadData();
          payload.roles = this.prepareRoles();
          const { data, meta } = await this.getUsers(payload);
          this.employees = this.formatEmployeesData(data);

          this.setTableInfo(meta);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },
      formatEmployeesData(employees) {
        return employees.map(employee => {
          const userRole = employee.userRoles[0];
          const phone = employee.phones.length
            ? this.parsePhone(employee.phones[0])
            : "não informado";

          return {
            id: employee.id,
            fullName: `${employee.firstName} ${employee.surname}`,
            photoHash: employee.photo?.hash ?? null,
            cpf: employee.cpf ?? "não informado",
            profile: userRole?.role?.description ?? "não informado",
            crc: userRole?.crc?.description ?? "não informado",
            phone,
            situation: employee.situationUser.description,
            email: employee.email,
            isSelectable: this.userCanManageEmployee(employee)
          };
        });
      },
      async fetchEmployeesFromCrc() {
        try {
          const { data, meta } = await this.getUsersFromCRC({
            id: this.crcId,
            data: this.filters
          });
          this.selectedEmployees = this.formatEmployeesData(data);
        } catch (error) {
          this.toggleSnackbar({
            text:
              error.data?.message ??
              "Ocorreu um erro ao carregar funcionários, tente novamente",
            type: "error"
          });
        }
      },
      userCanManageEmployee(employee) {
        if (this.userIsAdmin) {
          return true;
        }

        return !this.employeeIsManagerInCurrentCRC(employee);
      },
      employeeIsManagerInCurrentCRC(employee) {
        const userRole = employee.userRoles[0];
        const isManager = userRole.role?.id === profiles.MANAGER_OPERATING.id;
        const isSameCrcAsLoggedUser = this.employeeIsSameCrcAsLoggedUser(
          employee
        );
        return isManager && isSameCrcAsLoggedUser;
      },
      employeeIsSameCrcAsLoggedUser(employee) {
        return employee.userRoles.some(
          userRole => userRole.crc?.id === this.crcId
        );
      },
      goToAddUser() {
        this.$router.push({ name: "AddUser" });
      },
      backToViewCrc() {
        const { id } = this.$route.params;
        this.$router.push({ name: "ViewCRC", params: { id } });
      },
      updateData() {
        this.fetchEmployees();
        this.fetchEmployeesFromCrc();
      },
      removeEmployee(employee) {
        this.selectedEmployees = this.selectedEmployees.filter(
          selectedEmployee => selectedEmployee.id !== employee.id
        );
      },
      mountPayloadUpdateStaff() {
        return {
          usersIds: this.selectedEmployees.map(selectedEmployee => {
            return selectedEmployee.id;
          })
        };
      },
      async handleUpdateStaff() {
        try {
          const response = await this.updateStaff({
            id: this.crcId,
            data: this.mountPayloadUpdateStaff()
          });

          this.toggleSnackbar({
            text: response?.data?.message ?? "Registro alterado com sucesso",
            type: "success"
          });

          this.updateData();
        } catch (error) {
          this.toggleSnackbar({
            text:
              error?.data?.message ??
              "Não foi possível atualizar funcionários da CRC",
            type: "error"
          });
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  .v-card,
  .fill-height {
    min-width: inherit;
    min-height: inherit;
  }
</style>
