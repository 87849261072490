export const UserSituation = {
  PENDING: {
    id: 1,
    description: "Pendente"
  },
  ACTIVE: {
    id: 2,
    description: "Ativo"
  },
  BLOCKED: {
    id: 3,
    description: "Bloqueado"
  }
};
